const variables = {
  assetsPrefix: 'https://hodl-assets.fly.dev/s',
  apiHost: 'https://api.hodldash.io',
  colors: {
    background: 'rgb(0, 3, 22)',
    black: 'rgb(0, 3, 23)',
    blue: 'rgb(0, 152, 255)',
    chatPurple: '#0C0F25',
    grey: 'rgb(133, 134, 143)',
    green: '#50E89D',
    marineBlue: 'rgb(17, 19, 49)',
    purple: 'rgb(36, 41, 107)',
    purpleOpac: 'rgba(36, 41, 107, 0)',
    red: '#E85050',
    white: 'rgb(237, 237, 237)',
    yellow: 'rgb(254, 212, 7)'
  },
  desktop: {
    backgroundsMediaQuery: '@media screen and (min-width: 1670px)',
    mediaQuery: '@media screen and (min-width: 901px)',
    device: {
      minWidth: '901px'
    },
    header: {
      width: '1630px'
    },
    body: {
      minWidth: '1420px'
    }
  },
  fonts: {
    default: 'Jost, Helvetica Neue, Arial, sans-serif'
  },
  scaled: {
    backgroundsMediaQuery: '@media screen and (min-width: 901px) and (max-width: 1669px)'
  },
  mobile: {
    backgroundsMediaQuery: '@media screen and (max-width: 900px)',
    mediaQuery: '@media screen and (max-width: 900px)',
    device: {
      maxWidth: '900px'
    }
  },
  mobileWidth: 900,
  scaleWidth: 1670,
  additionalBnbPaidOut: 20353.69605,
  bnbPerNft: 0.2,
  estInvestmentFunds: 0,
  legalLinks: [
    {
      url: '/terms',
      label: 'Terms'
    },
    {
      url: '/privacy',
      label: 'Privacy'
    },
    {
      url: '/cookies',
      label: 'Cookies'
    },
    {
      url: '/disclaimers',
      label: 'Disclaimers'
    }
  ],
  links: {
    aboutgames: 'https://hodltoken.net/play-to-earn',
    aboutminigames: 'https://hodltoken.net/play-to-earn#mini-games',
    abouthodlhands: 'https://hodltoken.net/hodl-hands-nfts',
    abouthodl: 'https://hodltoken.net/about-our-token',
    achievements: 'https://hodltoken.net/achievements',
    announcementsontg: 'https://t.me/HodlAnnouncements',
    appsecurity: 'https://hodltoken.net/app#security',
    artpack: 'https://hodl-assets.fly.dev/s/49b77a14-98b7-4d7d-a62b-331e5e048ad4',
    audit: 'https://github.com/cyberscope-io/audits/blob/main/hodl/audit.pdf',
    binancesquare: 'https://www.binance.com/en/square/profile/hodl_official',
    bugbounty: 'https://hodltoken.net/bug-bounty',
    buy: 'https://pancakeswap.finance/swap?outputCurrency=0x32b407ee915432be6d3f168bc1eff2a6f8b2034c',
    calculator: 'https://hodltoken.net/#calculator',
    claimrewards: 'https://hodltoken.net/claim',
    coingecko: 'https://coingecko.com/en/coins/hodl',
    coinmarketcap: 'https://coinmarketcap.com/currencies/hodl',
    communityguidelines: 'https://hodltoken.net/community-guidelines',
    contract: 'https://bscscan.com/token/0x32b407ee915432be6d3f168bc1eff2a6f8b2034c',
    dashboardOverview: '/dashboard',
    dashboardRewards: '/dashboard/rewards',
    dashboardReflections: '/dashboard/reflections',
    dashboardReinvestments: '/dashboard/reinvestments',
    dashboardTransactions: '/dashboard/transactions',
    dashboardHolders: '/dashboard/hodlers',
    dashboardAddresses: '/dashboard/addresses',
    dashboardVideo: 'https://www.youtube.com/watch?v=9jFVbA5nkWM',
    dextools: 'https://www.dextools.io/app/bsc/pair-explorer/0xC5c4F99423DfD4D2b73D863aEe50750468e45C19',
    discord: 'https://discord.com/invite/D666jh5C5D',
    downloadandroidapp: 'https://hodltoken.net/android-app',
    downloadappleapp: 'https://hodltoken.net/apple-app',
    elementnftmarket: 'https://www.element.market/collections/hodl-hands-nfts',
    facebook: 'https://facebook.com/HODLtokenBSC',
    gamecoincatcher: 'https://hodltoken.net/play-to-earn/coin-catcher',
    gemminer: 'https://hodltoken.net/play-to-earn/gem-miner',
    gamehodlninja: 'https://hodltoken.net/play-to-earn/crypto-slash',
    gamemoonshot: 'https://hodltoken.net/play-to-earn/moonshot',
    games: 'https://hodltoken.net/play-to-earn',
    gemfighter: 'https://hodltoken.net/gem-fighter-p2e',
    gemfighterbeta: 'https://drive.google.com/file/d/1yD_RSuBDUvFDJ4xo6_35oJ-2N-A5h7aN/view?usp=drive_link',
    gemfighterbetamac: 'https://drive.google.com/file/d/1uH-aBa7XQMNpUKqgeEMGBLN1j5oeJxBn/view?usp=sharing',
    gemfightercontract: 'https://bscscan.com/address/0x1dc59c50d21514895a88d10f17af8fb1017e6a37#code',
    gemfightervideos: 'https://www.youtube.com/watch?v=TIw8FZNK1HM&list=PLh50Cy8l4VJICLQUFvDEl4M3NzwGpY09J&index=1',
    gemfighteronx: 'https://twitter.com/GemFighterNFTs',
    gemfighterwebsite: 'https://gemfighter.game',
    gemviewer: 'https://hodltoken.net/gem-fighter-nfts/viewer',
    gfraritypack: 'https://hodltoken.net/gem-fighter-nfts-guide.pdf',
    github: 'https://github.com/HODL-org',
    hodlchart: 'https://dexscreener.com/bsc/0xc5c4f99423dfd4d2b73d863aee50750468e45c19',
    hodlhandscontract: 'https://bscscan.com/token/0x7E82123bCb6465133D6E9E1Ad94d0115DE041b3D',
    hodlhandsdeck: 'https://hodltoken.net/nft-deck',
    hodlhandsnfts: 'https://hodltoken.net/hodl-hands-nfts',
    hodlhandsoverview: 'https://hodltoken.net/nft-overview',
    instagram: 'https://instagram.com/hodltoken',
    investordeck: 'https://hodltoken.net/deck',
    kyc: 'https://github.com/coinscope-co/kyc/blob/main/hodl/kyc.png',
    linkedin: 'https://www.linkedin.com/company/hodl',
    linktree: 'https://linktr.ee/hodltoken',
    medium: 'https://medium.com/@HODL-token',
    merchandise: 'https://hodlstore.io',
    nftmintlivetwitter: 'https://twitter.com/HODL_Hands_Live',
    nftrade: 'https://nftrade.com/assets/bsc/0x7e82123bcb6465133d6e9e1ad94d0115de041b3d',
    nftscan: 'https://bnb.nftscan.com/0x7e82123bcb6465133d6e9e1ad94d0115de041b3d',
    nftsonhodlx: 'https://hodlx.exchange/nft-pools',
    nfttwitter: 'https://twitter.com/HODLHandsNFT',
    nftvideos: 'https://www.youtube.com/watch?v=a8sbIk-qKog&list=PLh50Cy8l4VJJpLxsPODlRcOOCSNc9RZYP',
    nomics: 'https://nomics.com/assets/hodlx2-hodlx',
    OurTeam: '/about#our-team',
    overviewpdf: 'https://hodltoken.net/PDF',
    playtoearnpr: 'https://finance.yahoo.com/news/dive-play-earn-games-earn-220500056.html',
    playtoearnvideo: 'https://hodltoken.net/videos/play-to-earn-gaming-coming-to-hodl',
    promotionalmaterials: 'https://hodltoken.net/promo-kit',
    rareboard: 'https://www.rareboard.com/hodl-hands',
    raritypack: 'https://hodl-assets.fly.dev/s/e738a5cb-952a-40be-a4a8-7e9bf86406de',
    reddit: 'https://reddit.com/r/HodlToken',
    refinable: 'https://hodl.refinable.store/',
    rewardstacking: 'https://hodltoken.net/stacking',
    risks: 'https://hodltoken.net/cryptocurrency-investment-risks',
    rugfreecoinsauditforhodl: 'https://github.com/Rugfreecoins/Smart-Contract-Audits/blob/main/HODL%20Token%20Audit%20.pdf',
    snapchat: 'https://www.snapchat.com/add/HodlToken',
    socialresources: 'https://hodltoken.net/resources/spread-the-word-about-hodl',
    solitaire: 'https://hodltoken.net/play-to-earn/solitaire',
    stacking: 'https://hodltoken.net/stacking',
    stakehodl: 'https://hodlx.exchange/pools',
    stakenfts: 'https://hodlx.exchange/nft-pools',
    staketoearnnfts: 'https://hodlx.exchange/earn',
    telegram: 'https://t.me/hodlinvestorgroup',
    telegramForward: 'https://t.me/HodlForward',
    telegramapp: 'https://t.me/+7eDTngCs9RM4YTc0',
    threads: 'https://www.threads.net/@hodltoken',
    timeline: 'https://hodltoken.net/timeline',
    tofunft: 'https://tofunft.com/collection/hodl-hands/items',
    X: 'https://twitter.com/HODL_Official',
    twitterhodlhands: 'https://twitter.com/HODLHandsNFT',
    twitterhodlhandslive: 'https://twitter.com/HODL_Hands_Live',
    unicrypt: 'https://app.uncx.network/lockers/univ2/chain/56/address/0xc5c4f99423dfd4d2b73d863aee50750468e45c19',
    voteforhodl: 'https://linktr.ee/vote_hodl',
    vulkania: 'https://vulkania.io/coins/hodl',
    whatisstaking: 'https://www.coinbase.com/learn/crypto-basics/what-is-staking',
    whatisfarming: 'https://academy.binance.com/en/articles/what-is-yield-farming-in-decentralized-finance-defi',
    whatisimpermanentloss: 'https://hodltoken.net/resources/impermanent-loss-understanding-the-risks-and-rewards-of-liquidity-provision-in-decentralized-exchanges',
    yahoo: 'https://finance.yahoo.com/news/celebrating-three-years-hodl-pioneering-180600056.html',
    youtube: 'https://www.youtube.com/c/HODL-Official',
    youtubecommunity: 'https://www.youtube.com/@HODL-Official/community',
    youtubeapp: 'https://youtube.com/playlist?list=PLh50Cy8l4VJLFp8aFGodxtNXhrlpyVY4k&si=SrHIxX1OrD4uoBmK',
    youtubehub: 'https://www.youtube.com/watch?v=FIU-kGxFXu4&list=PLh50Cy8l4VJJcs8xgaXCg6sFYarRuYqAp',
    youtubenfts: 'https://www.youtube.com/watch?v=vJAOPfhGiv4&list=PLh50Cy8l4VJJpLxsPODlRcOOCSNc9RZYP',
    hodlverseinc: 'https://bestiejs.github.io/platform.js/',
    dashboard: '/dashboard',
    dashboardaddresses: '/dashboard/addresses',
    dashboardhodlers: '/dashboard/hodlers',
    dashboardreflections: '/dashboard/reflections',
    dashboardreinvestments: '/dashboard/reinvestments',
    dashboardrewards: '/dashboard/rewards',
    dashboardtransactions: '/dashboard/transactions'
  },
  navigation: [
    {
      identifier: 'key-info',
      name: 'Key Info',
      columns: [
        {
          name: 'DEFI’S ULTIMATE REWARDS ECOSYSTEM',
          links: [
            {
              url: '/about',
              label: 'About',
              description: '{WORLD} The HODL Ecosystem & How It Works',
              quicklink: false
            },
            {
              url: '/whitepaper',
              label: 'Whitepaper',
              description: '{DOCUMENT} The Blueprint for Innovation & Tokenomics',
              quicklink: false
            },
            {
              url: '/roadmap',
              label: 'Roadmap',
              description: '{COMPASS} Huge Milestones Delivered, Big Plans Ahead',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://hodltoken.net/achievements',
              label: 'Achievements',
              description: '{BIONIC_ARM} Dominating DeFi Since May 2021',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/deck',
              label: 'Investor Deck',
              description: '{DOWNLOAD} Unlock the Full Power of HODL (PDF)',
              quicklink: false
            },
            {
              url: '/about#our-team',
              label: 'Team',
              description: '{TEAM} Doxxed Leadership with a Proven Track Record',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: '/security',
              label: 'Security',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/cryptocurrency-investment-risks',
              label: 'Investment Risks',
              description: '',
              quicklink: true
            },
            {
              url: 'https://t.me/HodlAnnouncements',
              label: 'Announcements »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/timeline',
              label: 'Timeline',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: '$hodl',
      name: '$HODL',
      columns: [
        {
          name: 'THE MOST REWARDING TOKEN IN DEFI',
          links: [
            {
              url: 'https://pancakeswap.finance/swap?outputCurrency=0x32b407ee915432be6d3f168bc1eff2a6f8b2034c',
              label: 'Buy $HODL Now »',
              description: '{ORANGE_DIAMOND} Hold & Earn BNB on Repeat (Slippage: 6%)',
              quicklink: false
            },
            {
              url: '/exchanges',
              label: 'Where to Buy',
              description: '{BUY} Get In Now – DEX Listings & Info',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/#calculator',
              label: 'Calculate Rewards',
              description: '{CALCULATOR} See Exactly What You’ll Earn Inc. APR',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://hodltoken.net/about-our-token',
              label: 'About',
              description: '{KING} The King of BNB Rewards',
              quicklink: false
            },
            {
              url: 'https://dexscreener.com/bsc/0xc5c4f99423dfd4d2b73d863aee50750468e45c19',
              label: 'Chart »',
              description: '{CHART} Track HODL’s Growth Live on DEX Screener',
              quicklink: false
            },
            {
              url: '/dashboard',
              label: 'Dashboard',
              description: '{DASHBOARD} Track Your Investment Performance & Key Info',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodltoken.net/claim',
              label: 'Claim BNB Rewards',
              description: '',
              quicklink: true
            },
            {
              url: 'https://coinmarketcap.com/currencies/hodl',
              label: 'CoinMarketCap »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://coingecko.com/en/coins/hodl',
              label: 'CoinGecko »',
              description: '',
              quicklink: true
            },
            {
              url: '/ranks',
              label: 'Investor Rankings',
              description: '',
              quicklink: true
            },
            {
              url: 'https://bscscan.com/token/0x32b407ee915432be6d3f168bc1eff2a6f8b2034c',
              label: 'Contract on BscScan »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/cyberscope-io/audits/blob/main/hodl/audit.pdf',
              label: 'Audit Report »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://app.uncx.network/lockers/univ2/chain/56/address/0xc5c4f99423dfd4d2b73d863aee50750468e45c19',
              label: 'Liquidity Locked »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/coinscope-co/kyc/blob/main/hodl/kyc.png',
              label: 'Doxxed & KYC Verified »',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'play-to-earn',
      name: 'Play-to-Earn',
      columns: [
        {
          name: 'PLAY GAMES & EARN REWARDS',
          links: [
            {
              url: 'https://hodltoken.net/play-to-earn',
              label: 'About',
              description: '{GAMING} Play Games, Earn Big, Level Up',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/crypto-slash',
              label: 'Crypto Slash',
              description: '{SWORDS} Slash Coins, Dodge Bombs, Stack $HODL',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/solitaire',
              label: 'Solitaire',
              description: '{CARDS} A Classic, Now Paying You to Win',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://hodltoken.net/play-to-earn/gem-miner',
              label: 'Gem Miner',
              description: '{PICKAXE} Match, Mine & Bank Rewards',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/coin-catcher',
              label: 'Coin Catcher',
              description: '{PIGGYBANK} Grab Coins, Stack Profits, Cash Out',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/play-to-earn/moonshot',
              label: 'Moonshot',
              description: '{ROCKET} Jump Planets, Stay Alive, Get Paid',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodltoken.net/gem-fighter-p2e',
              label: 'Play Gem Fighter Beta',
              description: '',
              quicklink: true
            },
            {
              url: 'https://gemfighter.game',
              label: 'Visit GemFighter.game',
              description: '',
              quicklink: true
            },
            {
              url: 'https://finance.yahoo.com/news/dive-play-earn-games-earn-220500056.html',
              label: 'Yahoo! Finance Press »',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'nfts',
      name: 'NFTs',
      columns: [
        {
          name: 'COMBINING ART, GAMING & REWARDS',
          links: [
            {
              url: '/hodl-hands-nfts',
              label: 'HODL Hands® NFTs',
              description: '{HODL_HANDS} Earn $HODL with Our Iconic NFTs',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/nft-pools',
              label: 'Stake HODL Hands® »',
              description: '{EARN} Get Gem Fighter NFTs: Stake HODL Hands®',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/nft-deck',
              label: 'HODL Hands® Pack',
              description: '{DOWNLOAD} Discover the Power of HODL Hands® (PDF)',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: '/gem-fighter-nfts',
              label: 'Gem Fighter NFTs',
              description: '{GEM} Battle, Win, Earn $HODL',
              quicklink: false
            },
            {
              url: 'https://hodlx.exchange/nft-pools',
              label: 'Stake Gem Fighter NFTs »',
              description: '{EARN} Get HODL Hands®: Stake Gem Fighter NFTs',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/gem-fighter-nfts-guide.pdf',
              label: 'Gem Fighter Pack',
              description: '{DOWNLOAD} Explore the Gem Fighter Universe (PDF)',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://hodlx.exchange/nft-pools',
              label: 'Swap Tokens for NFTs »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://twitter.com/HODLHandsNFT',
              label: 'HODL Hands® on X »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodl-assets.fly.dev/s/49b77a14-98b7-4d7d-a62b-331e5e048ad4',
              label: 'HODL Hands® Art Pack',
              description: '',
              quicklink: true
            },
            {
              url: 'https://twitter.com/GemFighterNFTs',
              label: 'Gem Fighter NFTs on X »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/gem-fighter-nfts/viewer',
              label: '3D Gemstone Viewer',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'app',
      name: 'App',
      columns: [
        {
          name: 'ONE APP – EVERYTHING HODL',
          links: [
            {
              url: 'app',
              label: 'About',
              description: '{APP} Our Entire Earnings Ecosystem in One App',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/android-app',
              label: 'Android App »',
              description: '{ANDROID} Download Now & Start Earning',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/apple-app',
              label: 'Apple App »',
              description: '{APPLE} Coming Soon – Be Ready',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'basic-app-user-guide',
              label: 'Lite Mode',
              description: '{LITE} Fast, Simple & Built for Beginners',
              quicklink: false
            },
            {
              url: 'pro-app-user-guide',
              label: 'Pro Mode',
              description: '{PRO} Advanced Tools to Maximize Profits',
              quicklink: false
            },
            {
              url: 'https://hodltoken.net/app#security',
              label: 'Security',
              description: '{SECURE} Military-Grade Protection for Your $HODL',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://youtube.com/playlist?list=PLh50Cy8l4VJLFp8aFGodxtNXhrlpyVY4k&si=SrHIxX1OrD4uoBmK',
              label: 'Videos',
              description: '',
              quicklink: true
            },
            {
              label: 'FAQs',
              description: '',
              quicklink: true
            },
            {
              url: 'https://t.me/+7eDTngCs9RM4YTc0',
              label: 'App Support Chat »',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'community',
      name: 'Community',
      columns: [
        {
          name: 'BECOME A HODLER',
          links: [
            {
              url: 'https://t.me/hodlinvestorgroup',
              label: 'Telegram »',
              description: '{CHAT} Join the Conversation with the Community',
              quicklink: false
            },
            {
              label: 'X »',
              description: '{X} Non-Stop Updates, Insights & News',
              quicklink: false
            },
            {
              url: 'https://www.binance.com/en/square/profile/hodl_official',
              label: 'Binance Square »',
              description: '{BINANCE_SQUARE} Join the Community on BNB\'s Binance Square',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: 'https://reddit.com/r/HodlToken',
              label: 'Reddit »',
              description: '{REDDIT} Stay Up to Date & Discuss HODL',
              quicklink: false
            },
            {
              url: '/case-studies',
              label: 'Case Studies',
              description: '{CASE_STUDIES} Real People, Real Profits, Real Success',
              quicklink: false
            },
            {
              url: '/charity',
              label: 'Charity',
              description: '{CHARITY} Making an Impact with the HODL Revolution',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://www.youtube.com/@HODL-Official/community',
              label: 'YouTube Community »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://discord.com/invite/D666jh5C5D',
              label: 'Discord »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://facebook.com/HODLtokenBSC',
              label: 'Facebook »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://instagram.com/hodltoken',
              label: 'Instagram »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://www.linkedin.com/company/hodl',
              label: 'LinkedIn »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/promo-kit',
              label: 'Promotional Materials »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://linktr.ee/vote_hodl',
              label: 'Vote for $HODL »',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    },
    {
      identifier: 'more',
      name: 'More',
      columns: [
        {
          name: 'EXPLORE MORE ABOUT HODL',
          links: [
            {
              url: '/news',
              label: 'News',
              description: '{NEWS} Breaking Updates from the HODL Ecosystem',
              quicklink: false
            },
            {
              url: '/faqs',
              label: 'FAQs',
              description: '{FAQS} The Quickest Way to Get Answers',
              quicklink: false
            },
            {
              url: 'https://hodlstore.io',
              label: 'Merchandise Store »',
              description: '{MERCH} Rep the Brand & Show Your HODL Status',
              quicklink: false
            }
          ]
        },
        {
          name: '',
          links: [
            {
              url: '/partners',
              label: 'Partners',
              description: '{PARTNERS} Meet the Key Players Behind HODL’s Success',
              quicklink: false
            },
            {
              url: '/history-of-hodl',
              label: 'Origin of \'HODL\'',
              description: '{ORIGIN} The Story Behind the Biggest Crypto Movement',
              quicklink: false
            },
            {
              url: '/videos',
              label: 'Videos',
              description: '{VIDEOS} Watch, Learn & Get Ahead',
              quicklink: false
            }
          ]
        },
        {
          name: 'Useful Links',
          links: [
            {
              url: 'https://linktr.ee/hodltoken',
              label: 'Linktree »',
              description: '',
              quicklink: true
            },
            {
              url: '/resources',
              label: 'Resource Library',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/resources/spread-the-word-about-hodl',
              label: 'Social Assets',
              description: '',
              quicklink: true
            },
            {
              url: 'https://medium.com/@HODL-token',
              label: 'Medium »',
              description: '',
              quicklink: true
            },
            {
              url: 'https://github.com/HODL-org',
              label: 'GitHub »',
              description: '',
              quicklink: true
            },
            {
              url: '/first-edition-coins',
              label: '1st Edition Coins',
              description: '',
              quicklink: true
            },
            {
              url: 'https://hodltoken.net/bug-bounty',
              label: 'Bug Bounty',
              description: '',
              quicklink: true
            }
          ]
        }
      ]
    }
  ],
  pages: {
    index: '/',
    aboutUs: '/about',
    app: '/app',
    caseStudies: '/case-studies',
    claim: '/claim',
    news: '/news',
    events: '/events',
    resourceLibrary: '/resources',
    exchanges: '/exchanges',
    faqs: '/faqs',
    fundraising: '/fundraising',
    games: '/play-to-earn',
    gemFighternfts: '/gem-fighter-nfts',
    gemFighterP2E: '/gem-fighter-p2e',
    history: '/history-of-hodl',
    hodlxExchanges: '/hodlx-exchanges',
    hodlForward: '/charity',
    hodlRanks: '/ranks',
    nfts: '/hodl-hands-nfts',
    ourPartners: '/partners',
    roadmap: '/roadmap',
    termsOfUse: '/terms',
    hodlCoins: '/first-edition-coins',
    cookies: '/cookies',
    disclaimers: '/disclaimers',
    privacy: '/privacy',
    communityrules: '/community-guidelines',
    bugBounty: '/bug-bounty',
    achievements: '/achievements',
    abouthodl: '/about-our-token',
    security: '/security',
    timeline: '/timeline',
    appBasicUserGuide: 'basic-app-user-guide',
    proAppUserGuide: 'pro-app-user-guide',
    aboutApp: 'app',
    risks: 'cryptocurrency-investment-risks',
    bitmart: 'bitmart-recovery',
    videos: '/videos',
    whitepaper: '/whitepaper',
    dashboard: '/dashboard',
    dashboardAddresses: '/dashboard/addresses',
    dashboardHodlers: '/dashboard/hodlers',
    dashboardReflections: '/dashboard/reflections',
    dashboardReinvestments: '/dashboard/reinvestments',
    dashboardRewards: '/dashboard/rewards',
    dashboardTransactions: '/dashboard/transactions'
  },
  projectFunds: '0x30CaF94164160d431338dF15aEDD11Fc9025ac33 - Investment wallet\n0x9b8f934beb96f2e5ddaad90a25adecf86017a0d1 - Deployer\n0xC3f41653168501B1A0c489bf1be0D6a054E34C1A - HODL Hands owner wallet\n0x0a199377F5489A285c70f03326b6D4BF769c10d3 - Marketing wallet\n0xc18406dd23aa13a06387c84f377f01cc5c187115 - Crypto.com card payment wallet\n0x02e4097a6710a45c13367dc067fad6b6d9d6b9d5 - HODL Store wallet\n0x1Be2bf839a71Fd30d2eadd324bDF959c1Bb0235B - Exchange Team\n0x894606f1eea0e37f5537a190da291b855fe685b1 - Company wallet'
};
export default variables;